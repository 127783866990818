import {Application as StimulusApplication} from "@hotwired/stimulus";
import EditToggleFormFieldController from "./edit_toggle_form_field_controller";
import LinksSideEffectsController from "./links_effects_controller";
import FilterToggleTaxExemptBiddersController from "./filter_toggle_tax_exempt_bidders_controller";
import FilterToggleWebcastSoldBidderTypeController from "./filter_toggle_webcast_sold_bidder_type_controller";
import FiltersSaveController from "./filters_save_controller";
import FiltersAppendController from "./filters_append_controller";
import PowerTurboModalController from "./power_turbo_modal_controller";
import BidderManagementController from "./bidder_management_controller";
import BulkImportBiddersController from "./bulk_import_bidders_controller";
import InvoicePaymentMethodOnlineCardController from "./invoice_payment_method_online_card_controller";
import FilterToggleInvoicesWithFailedPaymentsController from "./filter_toggle_invoices_with_failed_payments_controller";
import CountryRegionFieldsController from "./country_region_fields_controller";
import BootstrapJsController from "./bootstrap_js_controller";


export function startStimulusApp() {
    const stimulusApp = StimulusApplication.start();
    stimulusApp.register('bootstrap', BootstrapJsController);

    stimulusApp.register('filters-save', FiltersSaveController);
    stimulusApp.register('filters-append', FiltersAppendController);
    stimulusApp.register('filter-toggle-tax-exempt-bidders', FilterToggleTaxExemptBiddersController);
    stimulusApp.register('filter-toggle-invoices-with-failed-payments', FilterToggleInvoicesWithFailedPaymentsController);
    stimulusApp.register('filter-toggle-webcast-sold-bidder-type', FilterToggleWebcastSoldBidderTypeController);
    stimulusApp.register('edit-toggle-form-field', EditToggleFormFieldController);
    stimulusApp.register('links-side-effects', LinksSideEffectsController);
    stimulusApp.register('power-turbo-modal', PowerTurboModalController);
    stimulusApp.register('bidders-management', BidderManagementController);
    stimulusApp.register('country-region-fields', CountryRegionFieldsController);
    stimulusApp.register('bulk-import-bidders', BulkImportBiddersController);
    stimulusApp.register('invoice-payment-method-online-card', InvoicePaymentMethodOnlineCardController);
}
