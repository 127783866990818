import {ActionEvent, Controller} from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails"
import {TaggedLogger} from "@nextlot/core/utilities";

const _logger = TaggedLogger.get('CountryRegionFieldsController');


export default class CountryRegionFieldsController extends Controller {
    static targets = ['countryField', 'anchor'];

    private readonly hasAnchorTarget: boolean;
    private readonly anchorTarget: HTMLAnchorElement;

    private selectedCountry: string;


    countryFieldTargetConnected(element: HTMLSelectElement) {
        element.addEventListener('change', this.onCountryChange)
    }

    countryFieldTargetDisconnected(element: HTMLSelectElement) {
        element.removeEventListener('change', this.onCountryChange)
    }



    onCountryChange = (event: ActionEvent) => {

      const target = event.target as HTMLSelectElement
      this.selectedCountry = target.value

      if(! this.hasAnchorTarget) {
        throw 'ERROR Anchor!'
      }

      const selectedCountryParams = new URLSearchParams({ ['address_country_code']: this.selectedCountry })

      this.anchorTarget.search = selectedCountryParams.toString()
      this.anchorTarget.click()

    }
}

